var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-wrapper"},[_c('PageHeader',{attrs:{"screen-name":"Registro de Vacinação","link-items":_vm.linkItems}}),_c('main',{staticClass:"card"},[_c('validation-observer',{ref:"selectRules"},[_c('b-row',{staticClass:"pt-2 px-2"},[_c('b-col',{attrs:{"lg":"4","md":"4","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Unidade Operacional","label-for":"unidade-operacional-input","label-class":"font_size_label"}},[_c('validation-provider',{attrs:{"name":"unidade","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"unidade-operacional-input","options":_vm.unidadeSelect,"label":"descricao_unidade","placeholder":"Selecione uma unidade","clearable":true},on:{"input":_vm.searchEmpresa},model:{value:(_vm.params.unidade),callback:function ($$v) {_vm.$set(_vm.params, "unidade", $$v)},expression:"params.unidade"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Nenhuma opção selecionável.")])]),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"lg":"4","md":"4","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Empresa","label-for":"empresa-input","label-class":"font_size_label"}},[_c('validation-provider',{attrs:{"name":"empresa","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"select-empresa","variant":"custom","item-text":"descricao","item-value":"id_empresa","label":"nome_empresa","placeholder":"Selecione uma empresa","values":_vm.empresaSelect,"options":_vm.empresaSelect,"disabled":_vm.disabled.empresa},on:{"input":_vm.searchCampanha},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var nome_empresa = ref.nome_empresa;
var documento_formatado = ref.documento_formatado;
return [_c('strong',[_vm._v(_vm._s(nome_empresa))]),_c('br'),_c('span',[_vm._v(_vm._s(documento_formatado))])]}}],null,true),model:{value:(_vm.params.empresa),callback:function ($$v) {_vm.$set(_vm.params, "empresa", $$v)},expression:"params.empresa"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Nenhuma opção selecionável.")])]),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"lg":"4","md":"4","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Campanha","label-for":"campanha-input","label-class":"font_size_label"}},[_c('validation-provider',{attrs:{"name":"campanha","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"select-campanha","variant":"custom","label":"descricao_campanha","placeholder":"Selecione uma campanha","values":_vm.campanhaSelect,"options":_vm.campanhaSelect,"disabled":_vm.disabled.campanha},on:{"input":_vm.searchEmpresaEndereco},model:{value:(_vm.params.campanha),callback:function ($$v) {_vm.$set(_vm.params, "campanha", $$v)},expression:"params.campanha"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Nenhuma opção selecionável.")])]),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e()]}}])})],1)],1)],1),(_vm.showEndereco)?_c('b-row',{staticClass:"pt-2 px-2"},[_c('b-col',{attrs:{"lg":"8","md":"8","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Endereço","label-for":"endereco-input","label-class":"font_size_label"}},[_c('validation-provider',{attrs:{"name":"endereco","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"select-endereco","variant":"custom","label":"id_empresa_endereco","placeholder":"Selecione um endereço","values":_vm.enderecoSelect,"options":_vm.enderecoSelect,"disabled":_vm.disabled.endereco},scopedSlots:_vm._u([{key:"option",fn:function(data){return [_c('span',[_c('b',[_vm._v(_vm._s(data.descricao))]),_c('br'),_vm._v(" "+_vm._s(_vm.formatEndereco(data))+" ")])]}},{key:"selected-option",fn:function(data){return [_c('span',[_c('b',[_vm._v(_vm._s(data.descricao))]),_c('br'),_vm._v(" "+_vm._s(_vm.formatEndereco(data))+" ")])]}}],null,true),model:{value:(_vm.params.endereco),callback:function ($$v) {_vm.$set(_vm.params, "endereco", $$v)},expression:"params.endereco"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Nenhuma opção selecionável.")])]),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e()]}}],null,false,899724534)})],1)],1)],1):_vm._e(),_c('b-row',{staticClass:"justify-content-center pb-2"},[_c('b-button',{staticClass:"mr-2 cor_botao",attrs:{"type":"reset","variant":"outline-primary"},on:{"click":_vm.clearSearch}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"XIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Limpar")])],1),_c('b-button',{attrs:{"variant":"primary-button"},on:{"click":_vm.redirectToList}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"CheckIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Selecionar empresa")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }